<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="sub_ttl"><span>参加お申込みありがとうございました</span></h2>
          <h3 class="min_ttl">月例会概要</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>名称</dt>
              <dd>{{ model.title }}</dd>
            </dl>

            <dl class="flex">
              <dt>開催日</dt>
              <dd>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }} {{ model.open_time }}</dd>
            </dl>
            <dl class="flex" v-if="model.place_text">
              <dt>会場</dt>
              <dd v-html="model.place_text"></dd>
            </dl>
            <dl class="flex" v-if="price">
              <dt>お支払い額</dt>
              <dd>
                {{ delimited(price) }}円･税込
              </dd>
            </dl>
          </div>
        </div>
        <div class="cts_box">
          上記研修会へのお申込みを受け付けました。ありがとうございました。<br>
          自動送信メールが配信されておりますので内容をご確認ください。<br>
          塾生の方はマイページの「研修会参加履歴」からお申込み内容をご確認いただけます。<br>
          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerJoinEvents'}" class="submit basic arrow">研修会参加履歴へ</router-link>
          </div>
        </div>
      </div>
    </section>

    <PaygentStatusModal v-if="paygent_status === 'status_waiting' || paygent_status === 'status_authory_ok'" />
    <PaygentStatusFailedModal v-if="paygent_status === 'status_failed'" />
    <PaygentStatusCancelModal v-if="paygent_status === 'status_cancel'" />

  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import PaygentStatusFailedModal from "@/components/paygentStatusFailedModal.vue";
import PaygentStatusModal from "@/components/paygentStatusModal.vue";
import PaygentStatusCancelModal from '@/components/paygentStatusCancelModal.vue'

export default {
  mixins: [Libraries],
  components: {PaygentStatusModal, PaygentStatusFailedModal, PaygentStatusCancelModal},
  data() {
    return {
      id: this.$route.params.id,
      fetch_data: null,
      model: null,
      price: this.$route.query.price,
      paygent_status: 'status_paid'
    }
  },
  mounted() {
    // form token
    this.removeFormToken('Getsurei')

    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}getsurei/page/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft,
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.monthly_live
            // sync paygent status
            this.startPaygentStatusPolling((status) => {
              this.paygent_status = status
            });
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
